/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { Router as MyRouter } from '@reach/router';
import { navigate } from 'gatsby';
import axios from 'axios';

// ProtoTypes
import PropTypes from 'prop-types';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import PillarPageTemplate from '../page-components/PillarPageTemplate';

// Helpers
import capitalizeLetters from '../shared/Helpers/capitalizeLetters';

// API link
import API from '../shared/APIs';

// Style
import '../page-styles/pillar.scss';

/* -------------------------------------------------------------------------- */
/*                                Pillar Page                                 */
/* -------------------------------------------------------------------------- */

function PillarPage({ location }) {
  /* ------------------------------- HOOKS ---------------------------------- */
  const [pageData, setPageData] = useState({});
  const [loader, setLoader] = useState(true);

  // GET PILLARS PAGES
  useEffect(() => {
    if (location.pathname === '/pillar') {
      navigate('/');
    }
    axios
      .get(API('pages'))
      .then(({ data: { data } }) => {
        const getPathName = location.pathname.split('/')[2];
        const getPage = data.find(
          ({ attributes }) => attributes.slug === getPathName,
        );
        setPageData(getPage.attributes);
        setLoader(true);
      })
      .then(() => setLoader(false))
      .catch((error) => error);
  }, [location]);

  /* ****************************** RENDERING ****************************** */
  return (
    <PageLayout loader={loader} location={location}>
      <Seo title={capitalizeLetters(pageData.title)} />
      <MyRouter>
        <PillarPageTemplate
          pageData={pageData}
          location={location}
          path={`/pillar/${pageData.slug}`}
        />
      </MyRouter>
    </PageLayout>
  );
}

PillarPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PillarPage;
