/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, useScreenClass } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                           Menu Bar Langin Page                             */
/* -------------------------------------------------------------------------- */

function MenuBar({ className, pageData }) {
  /* ------------------------------- CONST ---------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'xs', 'mdl'].includes(screenClass);
  /* -------------------------------- HELPERS ------------------------------- */
  function GetMenu() {
    return (
      <>
        {pageData?.page_clusters?.data
          .sort((elm1, elm2) => elm1.id - elm2.id)
          .map((link) => (
            <>
              <li className="single-link text-center flex justify-center items-center active fluid">
                <Link to={`/cluster/${link.id}`} className="fluid">
                  <h5>{link.attributes.title}</h5>
                </Link>
              </li>
            </>
          ))}
      </>
    );
  }

  /* ******************************* RENDERING ****************************** */
  return (
    <Container className={`menu-section ${className}`}>
      <Container className={`${IS_MOBILE ? 'fluid' : ''}`}>
        <ul className="topMenu flex fluid">{GetMenu()}</ul>
      </Container>
    </Container>
  );
}

MenuBar.propTypes = {
  className: PropTypes.string,
  pageData: PropTypes.objectOf(PropTypes.object).isRequired,
};

MenuBar.defaultProps = {
  className: '',
};

export default MenuBar;
