/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { Link } from 'gatsby';

// ProtoTypes
import PropTypes from 'prop-types';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import BannerSection from '../BannerSection';
import MenuBar from '../MenuSection';

// Assets
import BANNER_IMG from '../../images/pillar-description/banner.png';

// Styles
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                Pillar Page                                 */
/* -------------------------------------------------------------------------- */

function PillarPageTemplate({ pageData }) {
  /* ****************************** RENDERING ****************************** */
  return (
    <Container className="test-page fluid" fluid>
      <BannerSection
        title={pageData.title}
        subtitle={pageData.sub_title}
        bannerImage={BANNER_IMG}
        description={pageData.blocks[0]?.description}
      />
      <MenuBar className="grayish-background" pageData={pageData} />
      <Container>
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <p className="breadcrumb__link pointer">
              {`${pageData.sub_title ? `${pageData.sub_title} : ` : ''}${
                pageData.title
              }`}
            </p>
          </Container>
        </Visible>
      </Container>
    </Container>
  );
}

PillarPageTemplate.propTypes = {
  pageData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default PillarPageTemplate;
